import React from "react"
import { Link } from "gatsby"
import Heading from "@/components/Heading"
import { SVGOpen } from "@/svg"
import * as style from "./style.module.scss"

const items = [
  {
    title: "TADA Token",
    descr: "TADATek governance & payment token",
    url: "/tada/",
  },
  {
    title: "TADATrust",
    descr: "A lightweight Cardano multiplatform and multifunctional wallet",
    url: "/wallet/",
  },
  {
    title: "TADAStake",
    descr: "Stake solution, earn ~5% ROI in ADA with TADA on top",
    url: "/stake/",
  },
  // {
  //   title: "TADASwap",
  //   descr: "Automated liquidity protocol, AMM DEX",
  //   url: "/swap/",
  // },
  // {
  //   title: "TADAKickstart",
  //   descr: "Decentralised fundraising",
  //   url: "/kickstart/",
  // },
  {
    title: "TADAMI",
    descr: "NFT marketplace with advanced features",
    url: "/nft/",
  },
  // {
  //   title: "TADAGraph",
  //   descr: "Cardano blockhain graphql indexers",
  //   url: "/graph/",
  // },
  // {
  //   title: "TADAData",
  //   descr: "B2B data storage and authorization solution",
  //   url: "/data/",
  // },
  {
    title: "Wiki",
    descr: "Cardano & TADATek information database",
    url: "/wiki/",
  },
  // {
  //   title: "Cardano List",
  //   descr: "Cardano tokens & projects list curated by TADATek",
  //   url: "/cardano-list/",
  // },
  // {
  //   title: "Cardano-Web3.js",
  //   descr: "JavaScript SDK for Cardano blockchain",
  //   url: "https://github.com/tadatek/cardano-web3.js",
  //   external: true,
  // },
  {
    title: "TADA Academy",
    descr: "Raise knowledge about blockchain",
    url: "/academy/",
  },
]

const Solutions = () => {
  return (
    <div className="tada__block">
      <Heading id="solutions">
        <strong>TADATek</strong> solutions
      </Heading>
      <div className="row">
        {items.map((item, key) => {
          return (
            <div key={key} className="col-md-4 col-sm-6 col-12">
              <div className={style.item}>
                <Link
                  to={item.url}
                  className={style.itemInner}
                  activeClassName={style.itemInnerActive}
                  partiallyActive
                  target={item.external ? "_blank" : ""}
                >
                  {item.external && (
                    <span className={style.itemInnerExternal}>
                      <span className="tada__icon">
                        <SVGOpen />
                      </span>
                    </span>
                  )}
                  <div className={style.itemInnerTitle}>{item.title}</div>
                  <div>{item.descr}</div>
                </Link>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Solutions
